import { Controller } from "@hotwired/stimulus";
import L from "leaflet";
import "leaflet-routing-machine";

let map: any;
let icon: any;
let mainIcon: any;

// Connects to data-controller="leaflet"
export default class extends Controller {
  static values = {
    eventCoord: Array,
    // steps: Array,
    routes: Array,
  };

  // declare stepsValue: any[];
  // declare hasStepsValue: boolean;
  declare eventCoordValue: any[];
  declare hasEventCoordValue: boolean;
  declare routesValue: any[];
  declare hasRoutesValue: boolean;

  connect() {
    map = this.hasEventCoordValue
      ? new L.Map("map", {
          center: new L.LatLng(this.eventCoordValue[0], this.eventCoordValue[1]),
          zoom: 9,
          zoomControl: true,
          scrollWheelZoom: false,
          attributionControl: false,
        })
      : new L.Map("map", {
          // coordonnées de Paris
          center: new L.LatLng(48.864716, 2.349014),
          zoom: 9,
          zoomControl: true,
          scrollWheelZoom: false,
          attributionControl: false,
        });

    icon = L.icon({
      iconUrl: "/marker-icon.png",
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
    });

    mainIcon = L.icon({
      iconUrl: "/main_icon.png",
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
    });

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      subdomains: ["a", "b", "c"],
    }).addTo(map);

    if (this.hasRoutesValue) {
      // console.log(JSON.stringify(this.routesValue[0]));
      // console.log(JSON.stringify(this.routesValue[1]));
      // format routeValue = [route_id, [ [step1.lat, step1.lgt],  [step2.lat, step2.lgt] ] ]
      console.log(typeof this.routesValue);
      this.routesValue.forEach((route) => {
        console.log("route");
        console.log(route);
        this.addRoute(route[1]);
      });
    }
    if (this.hasEventCoordValue) {
      this.addMarker(this.eventCoordValue[0], this.eventCoordValue[1], true);
    }

    // if (this.hasStepsValue) {
    //   this.addRoute(this.stepsValue);
    // }
  }

  addMarker(lat, lgt, main = false) {
    L.marker([lat, lgt], {
      icon: main ? mainIcon : icon,
      riseOnHover: true,
      zIndexOffset: main ? 2000 : 200,
      riseOffset: 5000,
    }).addTo(map);
  }

  addRoute(steps) {
    let waypoints: any[] = [];
    steps.forEach((step) => {
      console.log("step");
      console.log(step);
      waypoints.push(L.latLng(step[0], step[1]));
    });
    L.Routing.control({
      // waypoints: [L.latLng(44.8333, -0.5667), L.latLng(48.085329, -1.696846)],
      waypoints: waypoints,
      routeWhileDragging: true,
      lineOptions: {
        styles: [{ color: "#2564eb", opacity: 1, weight: 3 }],
      },
      // showAlternatives: true,
      formatter: new L.Routing.Formatter({ language: "fr" }),
      // cache les instructions de trajet
      containerClassName: "hidden",
      createMarker: function (i, wp, nWps) {
        return L.marker(wp.latLng, {
          icon: icon,
        });
      },
    }).addTo(map);
  }
}
